//正式地址
export const host='ht.forsite.cc/api/';
export const httpUrl=`https://${host}`;
export const wsUrl=`wss://${host}wsAdmin`;
export const httpUrlImg=`https://ht.forsite.cc/`;


//本地开发
// export const host='localhost:8800/api/';
// export const httpUrl=`http://${host}`;
// //图片地址
// export const httpUrlImg=`https://htccmc.top/api`;
// export const wsUrl=`ws://${host}wsAdmin`;
